<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Teams Voters Report</div>
    <div class="
        col-lg-6 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      "></div>
  </div>

  <ul class="nav nav-tabs" id="myTab" role="tablist" v-if="this.user_team_status_allow == 1">
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="myvoter-all-details-tab" data-bs-toggle="tab" data-bs-target="#myvoteralldetail"
        type="button" role="tab" aria-controls="vehicle-details" aria-selected="false" @click="redirectmyvoterurl()">
        My Voters
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="teamvoter-report-tab" data-bs-toggle="tab" data-bs-target="#teamvoterreport"
        type="button" role="tab" aria-controls="customer-details" aria-selected="true">
        Teams Voters
      </button>
    </li>
  </ul>
  <div class="tab-content mt-2" id="myTabContent">
    <div class="tab-pane fade show active" id="myvoteralldetail" role="tabpanel"
      aria-labelledby="myvoter-all-details-tab">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link custom-navbar-navlink" id="voter-all-details-tab" data-bs-toggle="tab"
            data-bs-target="#voteralldetail" type="button" role="tab" aria-controls="vehicle-details"
            aria-selected="false" @click="redirectteamvoterurl()">
            Voters Details
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link custom-navbar-navlink active" id="voter-report-tab" data-bs-toggle="tab"
            data-bs-target="#voterreport" type="button" role="tab" aria-controls="customer-details"
            aria-selected="true">
            Reports
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="voterreport" role="tabpanel" aria-labelledby="voter-report-tab">
          <div class="company-section-outer py-3" style="height: calc(100vh - 185px)">
            <div class="row g-3">
              <div class="col-lg-4 col-md-4 col-12">
                <div class="feedback-chart-outer pb-3">
                  <div class="chart-label text-center">Gender Wise Report</div>
                  <div v-if="checkEmptyDataGender">
                    <div style="position: relative;
    min-height: 350px;">
                      <div v-if="!showloadergenderreport">
                        <Chart type="doughnut" :data="chartDataGender" :options="lightOptions" responsive="true" />
                      </div>
                      <div v-if="showloadergenderreport">
                        <div class="custom-modal-spinner-loader">
                          <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div style="position: relative;min-height: 350px;"
                      class="d-flex align-items-center justify-content-center">
                      <div>
                        No data found
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="feedback-chart-outer pb-3">
                  <div class="chart-label text-center">Profession Wise Report</div>
                  <div v-if="checkEmptyDataPro">
                    <div style="position: relative;
    min-height: 350px;">
                      <div v-if="!showloaderproreport">
                        <Chart type="doughnut" :data="chartDataProfession" :options="lightOptions" responsive="true" />
                      </div>
                      <div v-if="showloaderproreport">
                        <div class="custom-modal-spinner-loader">
                          <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div style="position: relative;min-height: 350px;"
                      class="d-flex align-items-center justify-content-center">
                      <div>
                        No data found
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="feedback-chart-outer pb-3">
                  <div class="chart-label text-center">Blood Group Wise Report</div>
                  <div v-if="checkEmptyDataBlood">
                    <div style="position: relative;
    min-height: 350px;">
                      <div v-if="!showloaderblood">
                        <Chart type="doughnut" :data="chartDataBloodGroup" :options="lightOptions" responsive="true" />
                      </div>
                      <div v-if="showloaderblood">
                        <div class="custom-modal-spinner-loader">
                          <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div style="position: relative;min-height: 350px;" class="d-flex align-items-center justify-content-center">
                      <div>
                        No data found
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="voteralldetail" role="tabpanel" aria-labelledby="voter-all-details-tab"></div>
      </div>
    </div>
    <div class="tab-pane fade" id="teamvoterreport" role="tabpanel" aria-labelledby="teamvoter-report-tab"
      v-if="this.user_team_status_allow == 1">
      Teams Voters
    </div>
  </div>
</template>
<script>
import ApiService from "../../service/ApiService";

export default {
  data() {
    return {
      showloadergenderreport: false,
      showloaderproreport: false,
      showloaderblood: false,
      ReportChartDetails: "",
      user_team_status_allow: 0,
      checkEmptyDataGender: false,
    checkEmptyDataPro: false,
    checkEmptyDataBlood: false,
      user_info: '',
      chartDataGender: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          },
        ],
      },
      chartDataProfession: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          },
        ],
      },
      chartDataBloodGroup: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          },
        ],
      },
      lightOptions: {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
      },
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  mounted() {
    this.showloadergenderreport = true;
    this.showloaderproreport = true;
    this.showloaderblood = true;
    this.getvoterreportschart({ isTeam: 1 });
    this.user_info = JSON.parse(localStorage.user);
    this.user_team_status_allow = this.user_info.is_team;
    this.checkEmptyDataGender = true;
    this.checkEmptyDataPro = true;
    this.checkEmptyDataBlood = true;
  },
  methods: {
    redirectteamvoterurl() {
      this.$router.push("/voters/teamsvoters");
    },
    redirectmyvoterurl() {
      this.$router.push("/voters/myvoters");
    },
    getvoterreportschart(e) {
      this.ApiService.getmeranetagenderdetails(e).then((data) => {
        if (data.success == true) {
          this.chartDataGender = {
            labels: data.labels,
            datasets: [
              {
                data: data.data,
                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
              },
            ],
          };
          this.showloadergenderreport = false;
        } else {
          this.showloadergenderreport = false;
          this.checkEmptyDataGender = false;
        }
      });
      this.ApiService.getmeranetaprofessiondetails(e).then((data) => {
        if (data.success == true) {
          this.chartDataProfession = {
            labels: data.labels,
            datasets: [
              {
                data: data.data,
                backgroundColor: [
                  "#FF6384",
                  "#36A2EB",
                  "#FFCE56",
                  "#808000",
                  "#008000",
                  "#008080",
                  "#000080",
                  "#808080",
                  "#F08080",
                  "#FF8C00",
                  "#556B2F",
                ],
                hoverBackgroundColor: [
                  "#FF6384",
                  "#36A2EB",
                  "#FFCE56",
                  "#808000",
                  "#008000",
                  "#008080",
                  "#000080",
                  "#808080",
                  "#F08080",
                  "#FF8C00",
                  "#556B2F",
                ],
              },
            ],
          };
          this.showloaderproreport = false;
        }
        else {
          this.showloaderproreport = false;
          this.checkEmptyDataPro = false;
        }
      });
      this.ApiService.getmeranetabloodgroupdetails(e).then((data) => {
        if (data.success == true) {
          this.chartDataBloodGroup = {
            labels: data.labels,
            datasets: [
              {
                data: data.data,
                backgroundColor: ["#FF6384",
                  "#36A2EB",
                  "#FFCE56",
                  "#808000",
                  "#008000",
                  "#008080",
                  "#000080",
                  "#808080",
                  "#F08080",
                  "#FF8C00",
                  "#556B2F",],
                hoverBackgroundColor: ["#FF6384",
                  "#36A2EB",
                  "#FFCE56",
                  "#808000",
                  "#008000",
                  "#008080",
                  "#000080",
                  "#808080",
                  "#F08080",
                  "#FF8C00",
                  "#556B2F",],
              },
            ],
          };
          this.showloaderblood = false;
        }
        else {
          this.showloaderblood = false;
          this.checkEmptyDataBlood = false;
        }
      });
    },
  },
};
</script>
<style scoped>
.company-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  /* height: 520px; */
}

.company-section-outer::-webkit-scrollbar {
  display: block;
}

.company-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.feedback-chart-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
}

.feedback-chart-outer .chart-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #4a5463;
  text-align: left;
  line-height: 16px;
  padding: 16px;
}
</style>